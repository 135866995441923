import React from 'react'
import Layout from '@/components/Layout'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import PrivacyPolicyPage from '@/components/PrivacyPolicy'
import Breadcrumbs from '@/components/Common/Breadcrumbs'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" canonical={`${useLocation().host}`} />
      <Breadcrumbs title="Privacy Policy" />
      <PrivacyPolicyPage />
    </Layout>
  )
}

export default PrivacyPolicy
