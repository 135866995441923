import React from 'react'
import { Link } from 'gatsby'
type Props = {
  title: string
}
const Breadcrumbs: React.FC<Props> = ({ title }) => {
  return (
    <section
      className="about-section about-bg"
      style={{ backgroundImage: 'url(/img/sections/about/about-bg.png)' }}
    >
      <div className="container nav-container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10 col-12">
            <div className="about-inner desktop-center">
              <h2 className="title">{title}</h2>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <ul>
            <li>
              <Link to="/">Back To Home Page.</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Breadcrumbs
